import { Injectable } from "@angular/core";
import { BehaviorSubject, map, Observable } from "rxjs";

type UIState = {
  loading: boolean;
  error: any;
  selectedPlaceholderGroupId: number | null,
  selectedSmartSurveyTherapeuticDomainCode: string | null,
  selectedBuildingBlockTemplateTypeCode: string | null,
  selectedSessionTemplateDomainCode: string | null,
  selectedSeriesTemplateId: number | null,
  selectedProgramTemplateId: number | null,
  preferredSidePanelWidth: number;
};

@Injectable({
  providedIn: 'root'
})
export class UIStoreService {

  private initialState: UIState = {
    loading: false,
    error: null,
    selectedPlaceholderGroupId: null,
    selectedSmartSurveyTherapeuticDomainCode: null,
    selectedBuildingBlockTemplateTypeCode: null,
    selectedSessionTemplateDomainCode: null,
    selectedSeriesTemplateId: null,
    selectedProgramTemplateId: null,
    preferredSidePanelWidth: 398,
  };

  private uiStateSubject = new BehaviorSubject<UIState>(this.getInitialUIState());
  uiState$ = this.uiStateSubject.asObservable();

  constructor() { }

  private getInitialUIState(): UIState {
    const storedState = localStorage.getItem('uiState');
    if(storedState){
      const s = JSON.parse(storedState);
      return { ...this.initialState, ...s };
    } 
    return this.initialState;
  }

  getState(): UIState {
    return this.uiStateSubject.value;
  }

  private setState(newState: Partial<UIState>): void {
    const currentState = this.getState();
    const updatedState = { ...currentState, ...newState };
    localStorage.setItem('uiState', JSON.stringify(updatedState));
    this.uiStateSubject.next(updatedState);
  }

  getLoading(): Observable<boolean> {
    return this.uiState$.pipe(map((state) => state.loading));
  }

  setLoading(isLoading: boolean): void {
    this.setState({ loading: isLoading });
  }

  getSelectedPlaceholderGroupId(): Observable<number | null> {
    return this.uiState$.pipe(map((state) => state.selectedPlaceholderGroupId));
  }

  setSelectedPlaceholderGroupId(id: number | null): void {
    this.setState({ selectedPlaceholderGroupId: id });
  }

  getSelectedSmartSurveyTherapeuticDomainCode(): Observable<string| null> {
    return this.uiState$.pipe(map((state) => state.selectedSmartSurveyTherapeuticDomainCode));
  }

  setSelectedSmartSurveyTherapeuticDomainCode(id: string | null): void {
    this.setState({ selectedSmartSurveyTherapeuticDomainCode: id });
  }

  getSelectedBuildingBlockTemplateTypeCode(): Observable<string | null> {
    return this.uiState$.pipe(map((state) => state.selectedBuildingBlockTemplateTypeCode));
  }

  setSelectedBuildingBlockTemplateTypeCode(type: string | null): void {
    this.setState({ selectedBuildingBlockTemplateTypeCode: type });
  }

  getSelectedSessionTemplateDomainCode(): Observable<string | null> {
    return this.uiState$.pipe(map((state) => state.selectedSessionTemplateDomainCode));
  }

  setSelectedSessionTemplateDomainCode(code: string | null): void {
    this.setState({ selectedSessionTemplateDomainCode: code });
  }

  getSelectedSeriesTemplateId(): Observable<number | null> {
    return this.uiState$.pipe(map((state) => state.selectedSeriesTemplateId));
  }

  setSelectedSeriesTemplateId(id: number | null): void {
    this.setState({ selectedSeriesTemplateId: id });
  }

  getSelectedProgramTemplateId(): Observable<number | null> {
    return this.uiState$.pipe(map((state) => state.selectedProgramTemplateId));
  }

  setSelectedProgramTemplateId(id: number | null): void {
    this.setState({ selectedProgramTemplateId: id });
  }

  getPreferredSidePanelWidth(): Observable<number> {
    return this.uiState$.pipe(map((state) => state.preferredSidePanelWidth));
  }

  setPreferredSidePanelWidth(width: number): void {
    this.setState({ preferredSidePanelWidth: width });
  }

  resetState(): void {
    this.uiStateSubject.next(this.initialState);
  }
}