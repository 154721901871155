import { Component, OnDestroy } from '@angular/core';
import { Gender, SubriseUser } from '@beyou/app/core/models/users/subrise-user.model';
import { CustomerService } from '@beyou/app/core/store/features/customer.service';
import { UserService } from '@beyou/app/core/store/features/user.service';
import { Subscription, combineLatest, map } from 'rxjs';

interface IProfile {
  user: SubriseUser;
  customerName: string;
}

@Component({
  selector: 'app-profile-selector',
  templateUrl: './profile-selector.component.html',
  styleUrls: ['./profile-selector.component.scss']
})
export class ProfileSelectorComponent implements OnDestroy {
  profile: IProfile | null = null;
  subscription: Subscription;
  userImage!: string;

  constructor(
    private customerService: CustomerService,
    private userService: UserService
  ) {

    this.subscription = combineLatest([this.userService.currentUser$, this.customerService.currentCustomer$]).pipe(
      map(([user, customer]) => ({
        user: user,
        customerName: customer?.name
      } as IProfile))
    ).subscribe(profile => {
      if (profile && profile.user && profile.customerName) {
        this.profile = profile;
      } else {
        this.profile = null;
      }
    });
  }

  get userName(): string {
    return `${this.profile?.user?.firstName} ${this.profile?.user?.lastName}`;
  }

  ngOnInit(): void {
    this.userImage = this.userService.getProfilePictureUrl(this.profile?.user);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
