import { Locale } from "../locale.model";
import { ImageAsset } from "../media/cover.model";

export enum Gender {
  Male = 'male',
  Female = 'female',
  Other = 'other'
}

export enum UserRole {
  Admin = 'admin',
  Editor = 'editor',
  EndUser = 'enduser'
}

export type SubriseUserBase = {
  firstName: string;
  lastName: string;
  userName: string;
  displayName: string;
  email: string;
  gender: string;
  isTest: boolean;
  occupation?: string;
  birthday?: Date | null;
  companyName?: string;
  favoritePlace?: string;
  anchoringAction?: string;
  anchoringWord?: string;
  selfHypnosisAction?: string;
  locale: Locale;
  profilePicture?: ImageAsset | null;
  sessionIds: number[];
  smartUserProgramIds: number[];
}

export type SubriseUser = SubriseUserBase & {
  id: string;
  roles: string[];
}