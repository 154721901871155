<div class="action-container">
  <div>{{ description }}</div>
  <div>
    @if(isSaving){
      <button mat-raised-button color="primary" [disabled]="true">Saving</button>
    }
    @else{
      <button mat-raised-button color="primary" (click)="onButtonClicked()" [disabled]="!isEnabled">{{ label }}</button>
    }
  </div>
</div>
