import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { UIStoreService } from '@beyou/app/core/services/ui-store.service';

@Directive({
  selector: '[appLeftResizeHandle]'
})
export class LeftResizeHandleDirective {

  private startX = 0;
  private startWidth = 0;
  private panelEl!: HTMLElement;

  private unlistenMouseUp?: () => void;
  private unlistenMouseMove?: () => void;

  constructor(
      private el: ElementRef, 
      private renderer: Renderer2,
      private uiStoreService: UIStoreService,
  ) {}

  ngOnInit() {
    // The parent is the .right-panel in this example
    this.panelEl = this.el.nativeElement.parentElement;
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    event.preventDefault();
    this.startX = event.clientX;
    // Current width of the panel
    this.startWidth = this.panelEl.offsetWidth;

    // Listen to mousemove & mouseup on the document so we don't lose track
    this.unlistenMouseMove = this.renderer.listen('document', 'mousemove', this.onMouseMove);
    this.unlistenMouseUp = this.renderer.listen('document', 'mouseup', this.onMouseUp);
  }

  onMouseMove = (event: MouseEvent) => {
    const dx = event.clientX - this.startX;
    // Because we’re resizing from the left edge, subtract dx
    // so that as the user drags left, the panel becomes wider, etc.
    const newWidth = this.startWidth - dx;
    // Optionally clamp the width to min and max
    const minWidth = 398;
    const clampedWidth = Math.max(minWidth, Math.min(newWidth, minWidth * 2));
    this.renderer.setStyle(this.panelEl, 'width', `${clampedWidth}px`);
    this.uiStoreService.setPreferredSidePanelWidth(clampedWidth);
  };

  private cleanup() {
    console.debug('cleanup');
    // Stop listening to mousemove & mouseup
    this.unlistenMouseMove?.();
    this.unlistenMouseUp?.();
  }

  onMouseUp = (event: MouseEvent) => {
    this.cleanup();
  };

  ngOnDestroy() {
    this.cleanup();
  }

}
