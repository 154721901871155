import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Settings } from '@beyou/app/core/constants/settings';

@Component({
  selector: 'app-asset-upload-and-preview',
  templateUrl: './asset-upload-and-preview.component.html',
  styleUrl: './asset-upload-and-preview.component.scss'
})
export class AssetUploadAndPreviewComponent {
  @Input() assetUrl: string | null = null;
  @Input() fileExt = 'mp3';
  @Input() accept = '.mp3';
  @Input() maxSize = 150;  // Maximum supported size of file in MB, default is 150
  @Input() multipleFiles = false;
  @Input() uploadMessage = "Upload your file";
  @Input() instructions = "Drag and drop a file from your file system";
  @Output() changeFile = new EventEmitter();
  
  readonly maxFileSize = Settings.MAX_COVER_FILE_SIZE_MB;

  async onFileSelect(file: File) {
    console.log('file selected', file);
    if (file) {
      this.assetUrl = URL.createObjectURL(file);
      this.changeFile.emit(file);
    }
  }

}
