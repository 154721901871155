export const Settings = {
  DEFAULT_LANGUAGE: 'en-US',
  DEFAULT_LOCALE: 'nl-BE',
  DEFAULT_AUDIO_DURATION: 60,
  DEFAULT_TIME_SLOT_ID: 11,
  DEFAULT_PROGRAM_TEMPLATE_SESSION_FREQUENCY: 'every_day',
  DEFAULT_BLOCK_TYPE: 'standard',
  DEFAULT_BLOCK_CATEGORY: 'intro',
  POPUP_WIDTH_IN_PIXELS: 350,
  DEFAULT_THERAPEUTIC_DOMAIN_CODE: 'all_domains',
  DEFAULT_MAIN_BLOCK_PURPOSE_CODE: 'all',
  DEFAULT_MAIN_BLOCK_FREQUENCY_VARIATION_CODE : 'stable',
  DEFAULT_MAIN_BLOCK_RANGE_CODE : 'mid',
  DEFAULT_SNACKBAR_DURATION: 5000,
  DEFAULT_SESSION_TEMPLATE_BLOCK_PAUSE: 2,
  ICON_BACKGROUND_COLORS: [
    'rgba(84, 88, 247, 0.1)',
    'rgba(47, 225, 172, 0.1)',
    'rgba(247, 143, 84, 0.1)',
  ],
  MAX_COVER_FILE_SIZE_MB: 1,
  APP_VERSION: 'v20250313.1',
}