<div class="danger-container">
  @if(!!message){
    <div>Danger zone. {{ message }}</div>
  }
  @else{
    <div>Danger zone. This action cannot be undone.</div>
  }
  <div>
    <button mat-raised-button color="warn" (click)="onDeleteButtonClicked()" [disabled]="!isEnabled">Delete</button>
  </div>
</div>
