import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-action-button',
  templateUrl: './action-button.component.html',
  styleUrl: './action-button.component.scss'
})
export class ActionButtonComponent {
  @Input() isSaving = false;
  @Input() isEnabled = true;
  @Input() label!: string;
  @Input() description!: string;
  @Output() onClick = new EventEmitter<void>();


  onButtonClicked(): void {
    this.onClick.emit();
  }
}
