import { Component, Input } from '@angular/core';
import { UIStoreService } from '@beyou/app/core/services/ui-store.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss']
})
export class SidePanelComponent {
  @Input() containsDrag = false;
  panelWidth$ = this.uiStoreService.getPreferredSidePanelWidth();

  constructor(private uiStoreService: UIStoreService) { }

  
}
