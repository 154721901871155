/**
 * The Shared Module is used to hold and distribute reusable components, directives, and pipes
 * across multiple modules in an application.
 * Unlike the Core Module, which is typically imported once,
 * the Shared Module can be imported in any feature module where you need its functionalities.

 * More info at: https://angular.io/guide/sharing-ngmodules
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { OverlayModule } from '@angular/cdk/overlay';
import { CdkTreeModule } from '@angular/cdk/tree';
import { PortalModule } from '@angular/cdk/portal';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { MatBadgeModule } from '@angular/material/badge';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSliderModule } from '@angular/material/slider';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { ProfileSelectorComponent } from './components/profile-selector/profile-selector.component';
import { DurationFormatPipe } from './pipes/duration-format.pipe';
import { MenuButtonWithDropdownComponent } from './components/menu-button-with-dropdown/menu-button-with-dropdown.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { LoaderComponent } from './components/loader/loader.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { UnderContructionComponent } from './components/under-contruction/under-contruction.component';
import { SessionPlayerComponent } from './components/session-player/session-player.component';
import { SidePanelComponent } from './components/side-panel/side-panel.component';
import { ButtonComponent } from './components/button/button.component';
import { ButtonWithDropdownComponent } from './components/button-with-dropdown/button-with-dropdown.component';
import { SelectorMenuWithIconsComponent } from './components/selector-menu-with-icons/selector-menu-with-icons.component';
import { SelectorMenuWithIconsListItemComponent } from './components/selector-menu-with-icons-list-item/selector-menu-with-icons-list-item.component';
import { AssetUploadAndPreviewComponent } from './components/asset-upload-and-preview/asset-upload-and-preview.component';

import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DeleteButtonComponent } from './components/delete-button/delete-button.component';
import { ActionButtonComponent } from './components/action-button/action-button.component';
import { LeftResizeHandleDirective } from './directives/left-resize-handle.directive';


const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


const materialModules = [
  CdkTreeModule,
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatPaginatorModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatFormFieldModule,
  MatButtonToggleModule,
  MatTreeModule,
  OverlayModule,
  PortalModule,
  MatBadgeModule,
  MatGridListModule,
  MatRadioModule,
  MatDatepickerModule,
  MatTooltipModule,
  MatStepperModule,
  MatSliderModule,
  MatNativeDateModule,
  MatSlideToggleModule
];

@NgModule({
  declarations: [
    SideMenuComponent,
    ProfileSelectorComponent,
    DurationFormatPipe,
    MenuButtonWithDropdownComponent,
    PageHeaderComponent,
    FileUploadComponent,
    BreadcrumbsComponent,
    LoaderComponent,
    UnderContructionComponent,
    SessionPlayerComponent,
    SidePanelComponent,
    ButtonComponent,
    ButtonWithDropdownComponent,
    SelectorMenuWithIconsComponent,
    SelectorMenuWithIconsListItemComponent,
    AssetUploadAndPreviewComponent,
    DeleteButtonComponent,
    ActionButtonComponent,
    LeftResizeHandleDirective
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SideMenuComponent,
    ProfileSelectorComponent,
    MenuButtonWithDropdownComponent,
    LoaderComponent,
    PageHeaderComponent,
    FileUploadComponent,
    UnderContructionComponent,
    SessionPlayerComponent,
    SidePanelComponent,
    DurationFormatPipe,
    ButtonComponent,
    ButtonWithDropdownComponent,
    SelectorMenuWithIconsComponent,
    SelectorMenuWithIconsListItemComponent,
    AssetUploadAndPreviewComponent,
    DeleteButtonComponent,
    ActionButtonComponent,
    LeftResizeHandleDirective,
    ...materialModules,
  ],
  imports: [
    CommonModule,
    RouterModule,
    AngularSvgIconModule,
    MatMomentDateModule,  // Use Moment adapter instead of MatNativeDateModule
    ...materialModules
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'nl-BE' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class SharedModule { }
