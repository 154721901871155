import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-delete-button',
  templateUrl: './delete-button.component.html',
  styleUrl: './delete-button.component.scss'
})
export class DeleteButtonComponent {

  @Output() onDelete = new EventEmitter();
  @Input() message: string = '';
  @Input() isEnabled: boolean = true;

  onDeleteButtonClicked() {
    console.debug('Delete button clicked');
    this.onDelete.emit();
  }
}
