import { CoverDto } from "../../dto/media/cover-dto";
import { Cover } from "../../models/media/cover.model";
import { S3FileMapper } from "./s3-file.mapper";

export class CoverMapper {
  public static toModel(dto: CoverDto): Cover {
    return {
      upload: S3FileMapper.toModel(dto.upload),
      thumbs: {
        small: dto.small_thumb? S3FileMapper.toModel(dto.small_thumb) : null,
        medium: dto.medium_thumb? S3FileMapper.toModel(dto.medium_thumb) : null
      }
    }
  }

  public static toDto(model: Cover): CoverDto {
    return {
      upload: S3FileMapper.toDto(model.upload),
      small_thumb: model.thumbs.small? S3FileMapper.toDto(model.thumbs.small) : null,
      medium_thumb: model.thumbs.medium? S3FileMapper.toDto(model.thumbs.medium) : null
    }
  }
}

export const ImageAssetMapper = CoverMapper;