import moment from "moment";

export const toDateString = (date: Date): string => {
  const fm = moment(date).utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
  console.log('formatted date', fm);
  return fm;
}

export const getTime = (date:Date | any): number => {
  if(date instanceof Date){
    return date.getTime();
  } else {
    return date.toDate().getTime();
  }
}