<div class="cover-container">
  <div class="cover-image-container">
    <div class="cover-image" [style.backgroundImage]="'url(' + assetUrl + ')'">
      @if(!assetUrl){
        <mat-icon>image</mat-icon>
      }
    </div>
  </div>
  <app-file-upload 
    (changeFile)="onFileSelect($event)" 
    [accept]="accept" 
    [fileExt]="fileExt" 
    [instructions]="instructions"
    [uploadMessage]="uploadMessage"
    [maxSize]="maxFileSize">
  </app-file-upload>
</div>
